.instagram-floating-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #E4405F;
    color: white;
    border-radius: 50%;
    padding: 15px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
    z-index: 1000;
    transition: background-color 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  
  .instagram-floating-button:hover {
    background-color: #D9365D;
  }
  
  .instagram-icon {
    font-size: 24px;
  }
  