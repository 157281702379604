body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none; 
  scrollbar-width: none;
}

body::-webkit-scrollbar {
  display: none;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

a {
  color: white;
  text-decoration: none;
}

 @font-face {
  font-family: 'HarmonyGothicFont';
  src: url('./assets/fonts/GOTHIC.TTF');
 }

 @font-face {
  font-family: 'HarmonyBahnschriftFont';
  src: url('./assets/fonts/bahnschrift.ttf');
 }