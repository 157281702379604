@import '../../App.scss';

.nav-bar__container {
  align-items: center;
  background-color: $primary-color;
  display: flex;
  font-family: $font-family;
  font-weight: $font-weight-400;
  font-size: 26px;
  height: 126px;
  justify-content: space-between;
  line-height: $line-height;
  padding: 8px 24px 12px 152px;
  width: 100%;

  .nav-bar__logo {
    height: $height-logo-long-screen;
    width: $width-logo-long-screen;
  }

  .nav-bar__menu-toggle {
    display: none;
  }

  .nav-link {
    margin: 0px 16px;
  }

  .nav-link:hover {
    color: $secondary-color;
  }

  .nav-bar__page-link.active {
    color: $secondary-color;
  }
}

.secondary-nav__container {
  align-items: center;
  background-color: $primary-color;
  display: flex;
  flex-direction: column;
  font-family: HarmonyGothicFont;
  height: 336px;
  padding: 20px 0;
  top: 126px;
  width: 100%;
  z-index: 10;
  position: absolute;

  .nav-link {
    margin: 12px 0;
    text-align: center;
    width: 100%;
  }

  .nav-bar__social-link {
    margin: 8px 0;
  }
}

@media screen and (max-width: 1439px) {
  .nav-bar__container {
    padding-left: 48px;

    .nav-bar__logo {
      height: $height-logo-medium-screen;
      width: $width-logo-medium-screen;
    }

    .nav-bar__page-link.nav-link {
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      text-align: left;
    }
  }
}

@media screen and (max-width: 1023px) {
  .nav-bar__container {
    .nav-bar__menu-toggle {
      cursor: pointer;
      display: block;

      .nav-bar__menu-toogle-icon {
        color: $secondary-color;
        height: 56px !important;
        width: 64px !important;
      }
    }

    .nav-bar__page-links-container,
    .nav-bar__social-links-container {
      display: none;
    }
  }

  .secondary-nav__container {
    display: flex;
    flex-direction: column;

    .nav-bar__page-links-container,
    .nav-bar__social-links-container {
      display: flex;
      flex-direction: column;
      font-size: 24px;
    }
  }
}