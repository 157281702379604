@import '../../App.scss';

.footer-container {
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgb(16, 73, 67);
  background: linear-gradient(180deg, rgba(16, 73, 67, 1) 0%, rgba(2, 28, 25, 1) 94%);

  font-family: $font-family;
  font-weight: $font-weight-700;
  color: $third-color;
  justify-content: center;


  .footer__main-section {
    display: flex;
    align-items: center;
    max-width: 1200px;
  }

  .footer__logo-container {
    display: flex;
    border: none;
    border-left: 1px solid white;
    border-right: 1px solid white;
    margin: 0px 32px;

    .footer__logo {
      width: 460px;
      height: 224px;
    }
  }

  .footer-copyright {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    font-size: 20px;
  }
}

@media screen and (max-width: 1023px) {
  .footer-container {
    height: 764px;

    .footer__main-section {
      display: flex;
      flex-direction: column;
    }

    .footer__logo-container {
      margin: 16px 0px;
      border: none;
      border-top: 1px solid white;
      border-bottom: 1px solid white;

      .footer__logo {
        width: 260px;
        height: 108px;
      }
    }

    .footer-copyright {
      margin-top: 32px;
      font-size: 16px;
      max-width: 200px;
      text-align: justify;
    }
  }
}