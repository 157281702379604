.footer__info-section {
  flex: 1; /* Distribuye el espacio disponible entre los elementos */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .footer__info-section-title {
    font-size: 32px;
  }

  .footer__info-section-items {
    text-align: left;
  }

  .footer__info-section-item {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 400;

    .footer__info-section-icon {
      margin-right: 16px;
      width: 32px;
      height: 32px;
    }

    .footer__info-section-text {
      width: 200px;
      word-wrap: break-word;
      hyphens: auto;
      text-align: center;
    }
  }
}

@media screen and (max-width: 1023px) {
  .footer__info-section {
    .footer__info-section-title {
      font-size: 24px;
      line-height: 30px;
    }

    .footer__info-section-text {
      font-size: 16px;
      font-weight: 400;
      line-height: 30px;
    }
  }
}