$primary-color: #104943;
$secondary-color: #CB991FE8;
$third-color: #ffffff;
$width-logo-long-screen: 228px;
$height-logo-long-screen: 118px;
$width-logo-medium-screen: 164px;
$height-logo-medium-screen: 84px;
$font-family: 'HarmonyGothicFont';
$font-weight-400: 400;
$font-weight-700: 700;
$line-height: 30px;